import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { get, post } from "../../API/apiHelper";
import { toast, ToastContainer } from "react-toastify";
import { settings, updateSettings } from "../../API/endpoints";
import CircularProgressBar from "../Component/Loading";
import { Form, FormGroup, FormControl, FormLabel, Button } from 'react-bootstrap';
import supportimage from "../../Assets/Images/support.png";
import appsettings from "../../Assets/Images/phone-setup.png";
import adsettings from "../../Assets/Images/settings.png";

function Settings() {
    // const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        admin_name: "",
        admin_logo: null,
        firebase_file: null,
        // admin_version: "",
        par_page_limit: "",
        server_key: "",
        razorpay_key: "",
        // otp_length: "",
        password_length: "",
        app_name: "",
        app_logo: null,
        app_version: "",
        app_primary_color: "#000000",
        app_secondary_color: "#000000",
        app_other_color: "#000000",
        support_no: "",
        support_whatsapp_no: "",
        support_email: "",
        support_start_time: "",
        support_end_time: ""
    });

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === "file" ? files[0] : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {

            const response = await post(updateSettings, formData, true);
            if (response.ok) {
                toast.success("Settings updated successfully");
            } else {
                toast.error("Failed to update settings");
            }
        } catch (error) {
            console.error('Error updating settings:', error);
            toast.error("Failed to update settings");
        } finally {
            setLoading(false);
        }
    };

    const fetchSettingsData = async () => {
        setLoading(true);
        try {
            const res = await get(settings, true);
            const response = await res.json();
            const data = response.data;
            console.log(data);
            setFormData({
                admin_name: data.admin_name || "",
                admin_logo: data.admin_logo || null,
                firebase_file: data.firebase_file || null,
                // admin_version: data.admin_version || "",
                par_page_limit: data.par_page_limit || "",
                server_key: data.server_key || "",
                razorpay_key: data.razorpay_key || "",
                // otp_length: data.otp_length || "",
                password_length: data.password_length || "",
                app_name: data.app_name || "",
                app_logo: data.app_logo || null,
                app_version: data.app_version || "",
                app_primary_color: data.app_primary_color || "#000000",
                app_secondary_color: data.app_secondary_color || "#000000",
                app_other_color: data.app_other_color || "#000000",
                support_no: data.support_no || "",
                support_whatsapp_no: data.support_whatsapp_no || "",
                support_email: data.support_email || "",
                support_start_time: data.support_start_time || "",
                support_end_time: data.support_end_time || ""
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSettingsData();
    }, []);

    return (
        <main id="content" role="main" className="main pointer-event">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="content container-fluid">
                <div className="mb-4 pb-2">
                    <h6 className="h1 mb-0 text-capitalize d-flex align-items-center gap-2">
                        <img src="https://ovogoscan.com/public/assets/back-end/img/system-setting.png" alt="" />
                        System Settings
                    </h6>
                </div>

                <div className="row">
                    <div className="col-12 mr-5 mb-5">
                        <div className="card">
                            <div className="border-bottom px-4 py-3">
                                <h5 className="mb-0 text-capitalize d-flex align-items-center gap-2">
                                    <img width="20" src={adsettings} alt="" />
                                    Admin settings
                                </h5>
                            </div>
                            <div className="card-body">
                                {loading ? (
                                    <CircularProgressBar />
                                ) : (
                                    <Form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <FormGroup className="col-md-4 col-12 mb-3">
                                                <FormLabel>ADMIN NAME</FormLabel>
                                                <FormControl type="text" name="admin_name" value={formData.admin_name} onChange={handleChange} placeholder="Enter admin name" />
                                            </FormGroup>
                                            <FormGroup className="col-md-4 col-12 mb-3">
                                                <FormLabel>ADMIN LOGO</FormLabel>
                                                <FormControl type="file" name="admin_logo" onChange={handleChange} />
                                            </FormGroup>
                                            {/* <FormGroup className="col-md-4 col-12 mb-3">
                                                <FormLabel>ADMIN VERSION</FormLabel>
                                                <FormControl type="text" name="admin_version" value={formData.admin_version} onChange={handleChange} placeholder="Enter admin version" />
                                            </FormGroup> */}
                                            <FormGroup clFassName="col-md-4 col-12 mb-3">
                                                <FormLabel>PAR PAGE LIMIT</FormLabel>
                                                <FormControl type="text" name="par_page_limit" value={formData.par_page_limit} onChange={handleChange} />
                                            </FormGroup>
                                            <FormGroup className="col-md-4 col-12 mb-3">
                                                <FormLabel>SERVER KEY</FormLabel>
                                                <FormControl type="text" name="server_key" value={formData.server_key} onChange={handleChange} />
                                            </FormGroup>
                                            <FormGroup className="col-md-4 col-12 mb-3">
                                                <FormLabel>RAZORPAY KEY</FormLabel>
                                                <FormControl type="text" name="razorpay_key" value={formData.razorpay_key} onChange={handleChange} />
                                            </FormGroup>
                                            {/* <FormGroup className="col-md-4 col-12 mb-3">
                                                <FormLabel>OTP LENGTH</FormLabel>
                                                <FormControl type="number" name="otp_length" value={formData.otp_length} onChange={handleChange} />
                                            </FormGroup> */}
                                            <FormGroup className="col-md-4 col-12 mb-3">
                                                <FormLabel>PASSWORD LENGTH</FormLabel>
                                                <FormControl type="number" name="password_length" value={formData.password_length} onChange={handleChange} />
                                            </FormGroup>
                                        </div>
                                        <Button type="submit" className="mt-3">Update Admin Settings</Button>
                                    </Form>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="col-12 mr-5 mb-5">
                        <div className="card">
                            <div className="border-bottom px-4 py-3">
                                <h5 className="mb-0 text-capitalize d-flex align-items-center gap-2">
                                    <img width="20" src={appsettings} alt="" />
                                    App settings
                                </h5>
                            </div>
                            <div className="card-body">
                                {loading ? (
                                    <CircularProgressBar />
                                ) : (
                                    <Form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <FormGroup className="col-md-4 col-12 mb-3">
                                                <FormLabel>APP NAME</FormLabel>
                                                <FormControl type="text" name="app_name" value={formData.app_name} onChange={handleChange} placeholder="Enter app name" />
                                            </FormGroup>
                                            <FormGroup className="col-md-4 col-12 mb-3">
                                                <FormLabel>APP LOGO</FormLabel>
                                                <FormControl type="file" name="app_logo" onChange={handleChange} />
                                            </FormGroup>
                                            <FormGroup className="col-md-4 col-12 mb-3">
                                                <FormLabel>APP VERSION</FormLabel>
                                                <FormControl type="text" name="app_version" value={formData.app_version} onChange={handleChange} placeholder="Enter app version" />
                                            </FormGroup>
                                            <FormGroup className="col-md-4 col-12 mb-3">
                                                <FormLabel>FIREBASE</FormLabel>
                                                <FormControl
                                                    type="file"
                                                    name="firebase_file"
                                                    onChange={handleChange}
                                                    placeholder="Enter file"
                                                />
                                            </FormGroup>

                                            {/* <FormGroup className="col-md-4 col-12 mb-3">
                                                <FormLabel>APP PRIMARY COLOR</FormLabel>
                                                <FormControl type="color" name="app_primary_color" value={formData.app_primary_color} onChange={handleChange} />
                                            </FormGroup>
                                            <FormGroup className="col-md-4 col-12 mb-3">
                                                <FormLabel>APP SECONDARY COLOR</FormLabel>
                                                <FormControl type="color" name="app_secondary_color" value={formData.app_secondary_color} onChange={handleChange} />
                                            </FormGroup>
                                            <FormGroup className="col-md-4 col-12 mb-3">
                                                <FormLabel>APP OTHER COLOR</FormLabel>
                                                <FormControl type="color" name="app_other_color" value={formData.app_other_color} onChange={handleChange} />
                                            </FormGroup> */}
                                        </div>
                                        <Button type="submit" className="mt-3">Update App Settings</Button>
                                    </Form>
                                )}
                            </div>
                        </div>
                    </div>


                    <div className="col-12">
                        <div className="card">
                            <div className="border-bottom px-4 py-3">
                                <h5 className="mb-0 text-capitalize d-flex align-items-center gap-2">
                                    <img width="20" src={supportimage} alt="" />
                                    Support settings
                                </h5>
                            </div>
                            <div className="card-body">
                                {loading ? (
                                    <CircularProgressBar />
                                ) : (
                                    <Form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <FormGroup className="col-md-4 col-12 mb-3">
                                                <FormLabel>SUPPORT MOBILE NO</FormLabel>
                                                <FormControl type="number" name="support_no" value={formData.support_no} onChange={handleChange} />
                                            </FormGroup>
                                            <FormGroup className="col-md-4 col-12 mb-3">
                                                <FormLabel>WHATSAPP SUPPORT NO</FormLabel>
                                                <FormControl type="number" name="support_whatsapp_no" value={formData.support_whatsapp_no} onChange={handleChange} />
                                            </FormGroup>
                                            <FormGroup className="col-md-4 col-12 mb-3">
                                                <FormLabel>SUPPORT EMAIL</FormLabel>
                                                <FormControl type="email" name="support_email" value={formData.support_email} onChange={handleChange} />
                                            </FormGroup>
                                            <FormGroup className="col-md-4 col-12 mb-3">
                                                <FormLabel>SUPPORT START TIME</FormLabel>
                                                <FormControl type="time" name="support_start_time" value={formData.support_start_time} onChange={handleChange} />
                                            </FormGroup>
                                            <FormGroup className="col-md-4 col-12 mb-3">
                                                <FormLabel>SUPPORT END TIME</FormLabel>
                                                <FormControl type="time" name="support_end_time" value={formData.support_end_time} onChange={handleChange} />
                                            </FormGroup>
                                        </div>
                                        <Button type="submit" className="mt-3">Update Support Settings</Button>
                                    </Form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Settings;
