import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { post } from '../../../API/apiHelper';
import { add_subscription, del_subscription } from '../../../API/endpoints';
import { toast } from 'react-toastify';
import '../../../Assets/css/CloseButton.css';

const FaqcatTableRow = ({ subscription, onUpdate }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState({ subscription_id: '', plane_name: '', amount: '', validation_days: '', feature: '', type: '' });

  const handleShowEdit = () => {
    setModalData({
      subscription_id: subscription.id,
      plane_name: subscription.plane_name,
      amount: subscription.amount,
      validation_days: subscription.validation_days,
      type: subscription.type,
      feature: subscription.feature
    });
    setShowEditModal(true);
  };
  const handleCloseEdit = () => setShowEditModal(false);
  const handleSave = async () => {
    await post(
      add_subscription,
      {
        id: modalData.subscription_id,
        plane_name: modalData.plane_name,
        amount: modalData.amount,
        type: modalData.type,
        validation_days: modalData.validation_days,
        feature: modalData.feature,
      },
      true
    );
    toast.success('Subscription Update Successfully');
    handleCloseEdit();
    onUpdate();
  };

  const handleShowDelete = () => {
    setModalData({ subscription_id: subscription.id });
    setShowDeleteModal(true);
  }
  const handleCloseDelete = () => setShowDeleteModal(false);
  const handleDelete = async () => {
    await post(
      del_subscription,
      {
        id: modalData.subscription_id,
      },
      true
    );
    toast.success('Subscription Deleted Successfully');
    handleCloseDelete();
    onUpdate();
  };

  return (
    <>
      <tr>
        <td className="text-center">{subscription.id}</td>
        <td className="text-center">{subscription.plane_name || '-'}</td>
        <td className="text-center">{subscription.amount || '-'}</td>
        <td className="text-center">{subscription.type || '-'}</td>
        <td className="text-center">{subscription.validation_days || '-'}</td>
        <td className="text-center">{subscription.feature || '-'}</td>
        <td>
          <div className="d-flex justify-content-center gap-2">
            <Button variant="outline-info" size="sm" onClick={handleShowEdit}>
              <i className="tio-edit"></i>
            </Button>
            <Button variant="outline-danger" size="sm" onClick={handleShowDelete}>
              <i className="tio-delete"></i>
            </Button>
          </div>
        </td>
      </tr>

      {/* Modal for updating category */}
      <Modal show={showEditModal} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Update Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formCategoryName">
              <Form.Label>Plane Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Plane Name"
                value={modalData.plane_name}
                onChange={(e) =>
                  setModalData({ ...modalData, plane_name: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="formCategoryName">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="text"
                placeholder="Amount"
                value={modalData.amount}
                onChange={(e) =>
                  setModalData({ ...modalData, amount: e.target.value })
                }
              />
            </Form.Group>


            <Form.Group controlId="formCategoryName">
              <Form.Label>Discount type</Form.Label>
              <Form.Control
                type="text"
                placeholder="Discount type"
                value={modalData.type}
                onChange={(e) =>
                  setModalData({ ...modalData, type: e.target.value })
                }
              />
            </Form.Group>


            <Form.Group controlId="formCategoryName">
              <Form.Label>Validation days</Form.Label>
              <Form.Control
                type="text"
                placeholder="Validation days"
                value={modalData.validation_days}
                onChange={(e) =>
                  setModalData({ ...modalData, validation_days: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="formCategoryName">
              <Form.Label>Feature</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Validation days"
                value={modalData.feature}
                onChange={(e) =>
                  setModalData({ ...modalData, feature: e.target.value })
                }
              />
            </Form.Group>
            
            <Form.Control
              type="hidden"
              placeholder="subscription id"
              value={modalData.subscription_id}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEdit}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for delete confirmation */}
      <Modal show={showDeleteModal} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this category?
          <Form>
            <Form.Group controlId="formCategoryName">
              <Form.Control
                type="hidden"
                placeholder="Category id"
                value={modalData.category_id}
                onChange={(e) =>
                  setModalData({ ...modalData, category_id: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FaqcatTableRow;
