import React, { useState } from "react";
import { Modal, Button, Form ,FormControl ,FormLabel ,FormGroup} from 'react-bootstrap';
import { post, del } from '../../../API/apiHelper';
import { add_question, del_question } from '../../../API/endpoints';
import { toast } from 'react-toastify';
import '../../../Assets/css/CloseButton.css';

const QuestionTableRow = ({ Question, onUpdate }) => {

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState({
    label: '',
    answer: '',
    options: [],
    id: '',
    private: ''
  });

  // Ensure Question is defined
  if (!Question) return null;

  const handleShowEdit = () => {
    setModalData({
      label: Question.question || '',
      answer: Question.answer || '',
      options: Question.option ? Question.option.split(',').map(opt => opt.trim()) : [],
      id: Question.id,
      private: Question.private
    });
    setShowEditModal(true);
  };
  const handleCloseEdit = () => setShowEditModal(false);

  const handleShowDelete = () => {
    setModalData({ id: Question.id });
    setShowDeleteModal(true);
  };
  const handleCloseDelete = () => setShowDeleteModal(false);

  const handleSaveEdit = async () => {
    try {
      await post(
        add_question,
        {
          question: modalData.label,
          answer: modalData.answer,
          option: modalData.options,
          private: modalData.private,
          id: modalData.id
        },
        true
      );
      toast.success('Update Successfully');
      handleCloseEdit();
      onUpdate(); // Call the onUpdate callback to refresh data
    } catch (error) {
      toast.error('Update Failed');
    }
  };

  const handleDelete = async () => {
    try {
      await del(
        del_question,
        {
          id: modalData.id
        },
        true
      );
      toast.success('Deleted Successfully');
      handleCloseDelete();
      onUpdate(); // Call the onUpdate callback to refresh data
    } catch (error) {
      toast.error('Delete Failed');
    }
  };

  // Define optionsArray and optionsCount
  const optionsArray = Question.option ? Question.option.split(',').map(opt => opt.trim()) : [];
  const optionsCount = optionsArray.length;

  const handleOptionChange = (index, value) => {
    const newOptions = [...modalData.options];
    newOptions[index] = value;
    setModalData({ ...modalData, options: newOptions });
  };

  const handleAddOption = () => {
    setModalData({ ...modalData, options: [...modalData.options, ''] });
  };

  const handleRemoveOption = (index) => {
    setModalData({ ...modalData, options: modalData.options.filter((_, i) => i !== index) });
  };

  return (
    <>
      <tr>
        <td className="text-center">{Question.id}</td>
        <td className="text-center">{Question.question || "-"}</td>
        <td className="text-center">{Question.answer || "-"}</td>
        <td className="text-center">
          {optionsCount > 0 ? (
            optionsArray.map((opt, index) => (
              <div key={index}>{index + 1}. {opt}</div>
            ))
          ) : (
            "-"
          )}
        </td>
        <td>
          <div className="d-flex justify-content-center gap-2">
            <Button variant="outline-info" size="sm" onClick={handleShowEdit}>
              <i className="tio-edit"></i>
            </Button>
            <Button variant="outline-danger" size="sm" onClick={handleShowDelete}>
              <i className="tio-delete"></i>
            </Button>
          </div>
        </td>
      </tr>

      <Modal show={showEditModal} onHide={handleCloseEdit}>
        <Modal.Header closeButton className="customModalHeader">
          <Modal.Title>Edit Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formEditQuestion">
              <Form.Label>Question</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter question"
                value={modalData.label}
                onChange={(e) => setModalData({ ...modalData, label: e.target.value })}
              />
            </Form.Group>

            <Form.Group controlId="formEditAnswer">
              <Form.Label>Correct Answer</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter correct answer"
                value={modalData.answer}
                onChange={(e) => setModalData({ ...modalData, answer: e.target.value })}
              />
            </Form.Group>

            <Form.Group controlId="formEditOptions">
              <Form.Label>Options</Form.Label>
              {modalData.options.map((option, index) => (
                <div className="d-flex mb-2" key={index}>
                  <Form.Control
                    type="text"
                    placeholder={`Option ${index + 1}`}
                    value={option}
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                  />
                  <Button
                    variant="danger"
                    className="ms-2"
                    onClick={() => handleRemoveOption(index)}
                  >
                    Remove
                  </Button>
                </div>
              ))}
              <Button variant="primary" onClick={handleAddOption}>
                Add Option
              </Button>
            </Form.Group>

            <FormGroup>
              <FormLabel>Is Private</FormLabel>
              <FormControl
                as="select"
                value={modalData.private}
                onChange={(e) => setModalData({ ...modalData, private: e.target.value })}
              >
                <option value="">Select -</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </FormControl>
            </FormGroup>

            <Form.Group controlId="id">
              <Form.Control
                type="hidden"
                value={modalData.id}
                onChange={(e) => setModalData({ ...modalData, id: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEdit}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveEdit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleCloseDelete}>
        <Modal.Header closeButton className="customModalHeader">
          <Modal.Title>Delete Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this question?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default QuestionTableRow;
